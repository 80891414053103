import React, { FC } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { trimString, getBucketImage } from 'common/utils';
import { ILeaderboardEntryProps } from 'common/types';

import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss';

const LeaderboardEntry: FC<ILeaderboardEntryProps> = ({
	avatarUrl = '',
	name = '',
	username = '',
	rank = 0,
	scores = [],
	imagesData
}) => {
	const { t } = useI18next()
	const displayName = name || username;

	const goals = scores.find((score) => score.scoreType === 'totalgoals')
	const points = scores.find((score) => score.scoreType === 'totalpoints')

	return (
		<div className='border-red-tomato flex p-1 justify-evenly border-[4px] rounded-lg'>
			<div className='cell-text generic-cell'>{rank}</div>
			<div
				className='
					generic-cell
					flex
					overflow-hidden
					items-center
					justify-center
					mr-2
					flex-grow
				'
			>
				{avatarUrl
					? <img
						src={avatarUrl}
						className='
							border-2
							w-1/2
							max-w-[30px]
							aspect-[1/1]
							border-white
							rounded-full
							lg:max-w-[50px]
						'
					/>
					: <StaticImage
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/profileImages/profile-default-1.png'
						alt={t('tournaments.leaderboard.profilePic')}
						className='
							border-2
							w-1/2
							max-w-[30px]
							aspect-[1/1]
							border-white
							rounded-full
							lg:max-w-[50px]
						'
					/>
				}
			</div>
			<div className='cell-text username-cell flex-grow'> {trimString(displayName, 15)} </div>
			<div className='cell-text generic-cell'> {goals.value} </div>
			<div className='cell-text generic-cell'> {points.value} </div>
		</div>
	)
}

export default LeaderboardEntry;