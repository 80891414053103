import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';

import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss';

const TournamentsDesktopHero:FC<ISectionQueryData> = ({ imagesData }) => {
  const { t } = useTranslation();

  return (
    <BackgroundImage
      Tag='div'
      fluid={getBucketImage(imagesData, 'tournaments-hero-desktop-background.png', 'fluidData')}
      critical
      className='tournaments-hero-desktop-bg hidden lg:block'
    >
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/logo-shadow.png'
        alt={t('tournaments.hero.logos.desktop')}
        quality={100}
        placeholder='none'
        loading='eager'
        className='absolute transform left-1/2 bottom-0 -translate-x-1/2 aspect-[4.21] max-h-[30%]'
      />
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/moken-faceoff.png'
        alt={t('tournaments.hero.faceoff')}
        placeholder='none'
        loading='eager'
        className='w-full absolute bottom-0 aspect-[1916/794]'
      />
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/ml-champions-logo.png'
        alt={t('tournaments.hero.logos.desktop')}
        quality={100}
        placeholder='none'
        loading='eager'
        className='absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-[60%] aspect-[1.125] h-[60%] fhd:-translate-y-[70%]'
      />
    </BackgroundImage>
  )
}

export default TournamentsDesktopHero;
