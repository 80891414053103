import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useMediaChange from 'common/useMediaChange';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';
import { ESPORTS_STREAMING_ID, S3_BUCKET_URL } from 'common/const';

import { StaticImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';

import './styles.scss';

const EsportsStreaming: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();
	const { isDesktop } = useMediaChange();

  const backgroundFluidImageStack = [
    getBucketImage(imagesData, 'striped-bg.png', 'fluidData'),
    `linear-gradient(36deg, #1a1d1a 27.64%, #47135B 84%);`,
  ]

	return (
		<BackgroundImage
			id={ESPORTS_STREAMING_ID}
			fluid={backgroundFluidImageStack}
			Tag='section'
			className='esports-streaming-container'
		>
			<div className='flex flex-col items-center safe-space-container'>
				<h1 className='streaming-title'>{t("tournaments.streaming.titleFirstLine")}</h1>
				<h1 className='streaming-title'>{t("tournaments.streaming.titleSecondLine")}</h1>
				<div className='relative w-full lg:w-1/2 flex mt-[5%]'>
					{isDesktop && <StaticImage
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/moken-joystick-yellow.png'
						alt={t('tournaments.esportsStreaming.brasilMokenImgAlt')}
						objectFit='contain'
						className='
								w-auto
								absolute
								left-0
								h-[250%]
								top-[40%]
								transform
								-translate-x-1/2
								xl:h-auto
							'
					/>}
					<div className='
							w-full
							overflow-hidden
							border-4
							border-yellow-yolk
							rounded-xl
							lg:rounded-3xl
							lg:border-[10px]
						'
					>
						<video
							muted
							autoPlay
							loop
							playsInline
							className='w-full'
							src={S3_BUCKET_URL + 'videos/streaming.mp4'}
							poster={getBucketImage(imagesData, 'streaming-video-placeholder-desktop.png', 'fluidData')}
						/>
					</div>
					{isDesktop && <StaticImage
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/moken-joystick-red.png'
						alt={t('tournaments.esportsStreaming.portugalMokenImgAlt')}
						objectFit='contain'
						className='
								w-auto
								h-[130%]
								absolute
								right-0
								-top-[10%]
								transform
								translate-x-1/2
								-translate-y-[5%]
							'
					/>}
				</div>
			</div>
		</BackgroundImage>
	)
}

export default EsportsStreaming;