import React, { FC, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useMediaChange from 'common/useMediaChange';

import { getBucketImage } from 'common/utils';
import { ITournamentInformation, ITournamentTheme, ISectionQueryData } from 'common/types';
import { TOURNAMENTS_DETAILS_ID } from 'common/const';

import FadeSlider from '@global/FadeSlider';
import TournamentSlides from '@sections/Tournaments/TournamentsDetails/TournamentSlides';

import FadeSlide from '@global/FadeSlider/FadeSlide';

const TournamentsDetails: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();
	const { isMobile } = useMediaChange();

	const translationInfo: ITournamentInformation[] = t('tournaments.tournamentsDetails.information', { returnObjects: true });

	const information: ITournamentTheme[] = [
		{
			id: 'ml-champions',
			color: 'text-yellow',
			dotColor: 'bg-red',
			logoImage: getBucketImage(imagesData, 'ml-champions-logo', 'imageData'),
			bannerImage: getBucketImage(imagesData, 'ml-champions-prices', 'imageData'),
			textData: translationInfo[0]
		},
		{
			id: 'official-tournaments',
			color: 'text-green-lime',
			dotColor: 'bg-green-lime',
			logoImage: getBucketImage(imagesData, 'official-tournaments-logo', 'imageData'),
			bannerImage: getBucketImage(imagesData, 'official-tournaments-prices', 'imageData'),
			textData: translationInfo[1]
		},
		{
			id: 'community-tournaments',
			color: 'text-pink-lighter',
			dotColor: 'bg-pink-lighter',
			logoImage: getBucketImage(imagesData, 'community-tournaments-logo', 'imageData'),
			bannerImage: getBucketImage(imagesData, 'community-tournaments-prices', 'imageData'),
			textData: translationInfo[2]
		},
		{
			id: 'ranking-masters',
			color: 'text-blue-lighter',
			dotColor: 'bg-blue-lighter',
			logoImage: getBucketImage(imagesData, 'ranking-masters-logo', 'imageData'),
			bannerImage: getBucketImage(imagesData, 'ranking-masters-prices', 'imageData'),
			textData: translationInfo[3]
		}
	]

	const [currentIndex, setCurrentIndex] = useState<number>(0);

	return (
		<section
			id={TOURNAMENTS_DETAILS_ID}
			className='bg-gradient-to-b from-purple-dark to-purple-darker py-[2%] flex justify-center'
		>
			<div className='w-[90%] max-w-[640px] lg:max-w-[1300px]'>
				<FadeSlider
					pages={information}
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					paginationStyles='py-[10%] lg:py-[2%]'
					paginationSize={isMobile ? 'sm' : 'md'}
				>
					{
						information.map((info, ownIndex) => (
							<FadeSlide
								key={info.id}
								initial={{ opacity: 0 }}
								animate={{
									opacity: ownIndex === currentIndex ? 1 : 0,
									display: ownIndex === currentIndex ? 'block' : 'none',
								}}
								transition={{ duration: 2 }}
								className='w-full'
							>
								<TournamentSlides
									key={`${info.id}-slide`}
									currIndex={currentIndex}
									themes={information}
								/>
							</FadeSlide>
						))
					}
				</FadeSlider>
			</div>
		</section>
	)
}

export default TournamentsDetails;