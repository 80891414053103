import React, { FC } from 'react';

import { IGroupSelector } from 'common/types';

const GroupSelector:FC<IGroupSelector> = ({ currGroup = '', setRound, array }) => {
  return (
    <div className='w-full flex justify-center space-x-4'>
      {
        array?.map((round, index) => (
          <div
              key={round.indicator}
              onClick={() => setRound(index)}
              className={`
                  flex
                  justify-center
                  items-center
                  w-8
                  h-8
                  rounded-full
                  ${round.indicator === currGroup ? 'bg-blue' : 'bg-gray-midlight'}
              `}
          >
              <p className={`${round.indicator === currGroup ? 'text-white' : 'text-black'}`}>{round?.indicator || ''}</p>
          </div>
        ))
      }
    </div>
  )
}

export default GroupSelector;
