import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useMediaChange from 'common/useMediaChange';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';
import { SPONSORSHIPS_OPPORTUNITIES_ID } from 'common/const';

import InfoBox from '@sections/Tournaments/SponsorshipOpps/InfoBox';
import SponsorshipTiersMobile from '@sections/Tournaments/SponsorshipTiers/SponsorshipTiersMobile';
import SponsorshipTiersDesktop from '@sections/Tournaments/SponsorshipTiers/SponsorshipTiersDesktop';
import FadeSlider from '@global/FadeSlider';
import FadeSlide from '@global/FadeSlider/FadeSlide';

import './styles.scss';

const SponsorshipOpps: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();
	const { isMobile, isDesktop } = useMediaChange();

	const translationInfo: any[] = t('tournaments.sponsorshipsOpps.info', { returnObjects: true });

	const information = [
		{
			id: 'ml-champions-info-box',
			logo: getBucketImage(imagesData, 'ml-champions-logo', 'imageData'),
			title: translationInfo[0].title,
			description: translationInfo[0].description,
			dotColor: 'bg-red',
		},
		{
			id: 'official-tournaments-info-box',
			logo: getBucketImage(imagesData, 'official-tournaments-logo', 'imageData'),
			title: translationInfo[1].title,
			description: translationInfo[1].description,
			dotColor: 'bg-green-lime'
		},
		{
			id: 'community-tournaments-info-box',
			logo: getBucketImage(imagesData, 'community-tournaments-logo', 'imageData'),
			title: translationInfo[2].title,
			description: translationInfo[2].description,
			dotColor: 'bg-pink-lighter',
		},
		{
			id: 'ranking-masters-info-box',
			logo: getBucketImage(imagesData, 'ranking-masters-logo', 'imageData'),
			title: translationInfo[3].title,
			description: translationInfo[3].description,
			dotColor: 'bg-blue-lighter',
		},
	]

	const cards = [
    {
      mobileImage: [getBucketImage(imagesData, 'silver-sponsor-mobile-en.png', 'imageData'), getBucketImage(imagesData, 'silver-sponsor-mobile-es.png', 'imageData')],
      desktopImage: [getBucketImage(imagesData, 'silver-sponsor-desktop-en.png', 'imageData'), getBucketImage(imagesData, 'silver-sponsor-desktop-es.png', 'imageData')],
      imgAlt: t('tournaments.sponsorshipTiers.imgAlts.silver'),
      dotColor: 'bg-[#E1E1E1]',
    },
    {
      mobileImage: [getBucketImage(imagesData, 'gold-sponsor-mobile-en.png', 'imageData'), getBucketImage(imagesData, 'gold-sponsor-mobile-es.png', 'imageData')],
      desktopImage: [getBucketImage(imagesData, 'gold-sponsor-desktop-en.png', 'imageData'), getBucketImage(imagesData, 'gold-sponsor-desktop-es.png', 'imageData')],
      imgAlt: t('tournaments.sponsorshipTiers.imgAlts.gold'),
      dotColor: 'bg-[#F6C338]',
    },
    {
      mobileImage: [getBucketImage(imagesData, 'platinum-sponsor-mobile-en.png', 'imageData'), getBucketImage(imagesData, 'platinum-sponsor-mobile-es.png', 'imageData')],
      desktopImage: [getBucketImage(imagesData, 'platinum-sponsor-desktop-en.png', 'imageData'), getBucketImage(imagesData, 'platinum-sponsor-desktop-es.png', 'imageData')],
      imgAlt: t('tournaments.sponsorshipTiers.imgAlts.platinum'),
      dotColor: 'bg-[#A3C5DC]',
    },
    {
      mobileImage: [getBucketImage(imagesData, 'champ-series-sponsor-en.png', 'imageData'), getBucketImage(imagesData, 'champ-series-sponsor-es.png', 'imageData')],
      desktopImage: [getBucketImage(imagesData, 'champ-series-sponsor-en.png', 'imageData'), getBucketImage(imagesData, 'champ-series-sponsor-es.png', 'imageData')],
      imgAlt: t('tournaments.sponsorshipTiers.imgAlts.champSeries'),
      dotColor: 'bg-[#F70701]',
    }
  ];

	const [currentIndex, setCurrentIndex] = useState<number>(0);

	const goForward = () => {
		if (information.length === currentIndex + 1) {
			setCurrentIndex(0);
		}
		else {
			setCurrentIndex(currentIndex + 1);
		}
	}

	useEffect(() => {
		let interval;

		if (information.length > 1) {
			interval = setTimeout(goForward, 15000);
		}

		return () => clearInterval(interval);
	}, [currentIndex]);

	return (
		<section
			id={SPONSORSHIPS_OPPORTUNITIES_ID}
			className='sponsor-opps-bg'
		>
			<div
				className='
					w-[90%]
					max-w-[640px]
					flex
					flex-col
					lg:flex-row
					lg:max-w-[1300px]
					lg:space-x-8
					lg:mb-[5%]
				'
			>
				<div className='
						flex
						flex-col
						space-y-6
						lg:space-y-8
						lg:w-1/2
						lg:justify-end
					'
				>
					<p className='sponsor-opps-title'>
						{t('tournaments.sponsorshipsOpps.title')}
					</p>
					<div className='w-full flex justify-center'>
						<p className='sponsor-opps-body text-left'>
							{t('tournaments.sponsorshipsOpps.description.part1')}{' '}
							<span className='sponsor-opps-body-highlight'>
								{t('tournaments.sponsorshipsOpps.description.highlight')}{' '}
							</span>
							{t('tournaments.sponsorshipsOpps.description.part2')}
						</p>
					</div>
				</div>
				<div
					className='
							flex
							flex-col
							lg:hidden
						'
				>
					<FadeSlider
						pages={information}
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						paginationStyles='pb-6'
						paginationSize={isMobile ? 'sm' : 'md'}
					>
						{
							information.map((info, index) => (
								<FadeSlide
									key={info.id}
									initial={{ opacity: 0 }}
									animate={{
										opacity: index === currentIndex ? 1 : 0,
										display: index === currentIndex ? 'block' : 'none',
									}}
									transition={{ duration: 2 }}
								>
									<InfoBox
										logo={info.logo}
										title={info.title}
										description={info.description}
									/>
								</FadeSlide>
							))
						}
					</FadeSlider>
				</div>
				<div className='
							hidden
							lg:flex
							lg:flex-col
							lg:w-1/2
						'
				>
					{
						information.map(info => (
							<InfoBox
								key={info.id}
								logo={info.logo}
								title={info.title}
								description={info.description}
							/>
						))
					}
				</div>
			</div>
			{
				isDesktop
					? <SponsorshipTiersDesktop cards={cards} />
					: <SponsorshipTiersMobile cards={cards} />
			}
		</section>
	)
}

export default SponsorshipOpps;