import React from 'react';
import useMediaChange from 'common/useMediaChange';

import MLCSDesktop from './MLCSDesktop';
import MLCSMobile from './MLCSMobile';

const MLCS = () => {
  const { isDesktop } = useMediaChange();

  return (
    <>
      {
        isDesktop
        ? <MLCSDesktop />
        : <MLCSMobile />
      }
    </>
  )
}

export default MLCS;
