import React, { FC, useEffect, useState } from 'react';
import useMediaChange from 'common/useMediaChange';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { IGroupBracket, ISectionQueryData, ITournamentBracketData } from 'common/types';
import { getBucketImage } from 'common/utils';

import { Bracket } from 'react-brackets';
import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';
import FadeSlider from '@global/FadeSlider';
import FadeSlide from '@global/FadeSlider/FadeSlide';
import GroupSelector from './GroupSelector';
import CustomSeed from './CustomSeed';
import GroupIndicator from './GroupIndicator';
import Spinner from '@global/Spinner';

import './styles.scss';

const Brackets:FC<ISectionQueryData> = ({ imagesData }) => {
  const { isDesktop } = useMediaChange();
  const { t } = useTranslation();

  const [sliderIndex, setSliderIndex] = useState<number>(0);
  const [currentRound, setCurrentRound] = useState<number>(0);
  const [mobileBracketIndex, setMobileBracketIndex] = useState<number>(0);
  const [roundInfo, setRoundInfo] = useState<IGroupBracket>();
  const [bracketsData, setBracketsData] = useState<ITournamentBracketData>();

  useEffect(() => {
    const getBracketsData = async () => {
      try {
        const fetchResponse = await fetch(`${process.env.GATSBY_BRACKETS_URL}`);
        const data = await fetchResponse.json();
        setBracketsData(data);
      } catch (e) {
        console.error(e);
      }
    }

    getBracketsData();
  }, []);

  useEffect(() => {
    if(bracketsData) {
      setRoundInfo(bracketsData.bracket[0]);
    }
  }, [bracketsData])

  useEffect(() => {
    if(bracketsData) {
      setRoundInfo(bracketsData.bracket[currentRound]);
      setMobileBracketIndex(0);
    }
  }, [currentRound]);

  const getWinner = (bracketsArray: IGroupBracket[]) => {
    const lastBracket = bracketsArray[bracketsArray.length-1];
    const finale = lastBracket.rounds[lastBracket.rounds.length-1].seeds[0].teams;
    return finale[0].score > finale[1].score ? finale[0].name : finale[1].name;
  }

  const bracketsDisplayBg = [
    isDesktop
      ? getBucketImage(imagesData, 'granular-purple-bg-desktop.png', 'fluidData')
      : getBucketImage(imagesData, 'granular-purple-bg-mobile.png', 'fluidData'),
		'linear-gradient(0deg, black 25%, #47135B 75%)',
	]

  return (
		<section className='flex flex-col justify-center items-center'>
        <BackgroundImage
          Tag='div'
          fluid={bracketsDisplayBg}
          className='brackets-bg'
        >
          <div className='flex justify-center items-center safe-space-container'>
            {
              bracketsData
              ? bracketsData.bracket.length > 1
                ? isDesktop
                  ? <div>
                    {
                      bracketsData.bracket.length > 2 &&
                      <GroupIndicator
                        roundInfo={roundInfo}
                        currentRound={currentRound}
                        bracketsArray={bracketsData.bracket}
                      />
                    }
                    <FadeSlider
                      pages={bracketsData.bracket}
                      currentIndex={sliderIndex}
                      setCurrentIndex={setSliderIndex}
                      handleCallback={(index) => setCurrentRound(index)}
                      paginationStyles='py-[10%] lg:py-[5%]'
                      paginationSize='md'
                      fadeInterval={0}
                    >
                      {
                        bracketsData.bracket.map((round, ownIndex) => (
                          <FadeSlide
                            key={ownIndex}
                            initial={{ opacity: 0 }}
                            animate={{
                              opacity: ownIndex === sliderIndex ? 1 : 0,
                              display: ownIndex === sliderIndex ? 'block' : 'none',
                            }}
                            transition={{ duration: 2 }}
                            className='w-full pt-[4%]'
                          >
                            <Bracket
                              rounds={round.rounds}
                              renderSeedComponent={CustomSeed}
                              roundClassName='round-text'
                              mobileBreakpoint={1023}
                              bracketClassName='w-full lg:max-w-min'
                            />
                          </FadeSlide>
                        ))
                      }
                    </FadeSlider>
                  </div>
                  : <div className='w-full space-y-8'>
                    {
                      roundInfo &&
                      <>
                        <GroupSelector
                          array={bracketsData.bracket}
                          currGroup={roundInfo.indicator}
                          setRound={(index) => setCurrentRound(index)}
                        />
                        {
                          bracketsData.bracket.length > 2 &&
                          <GroupIndicator
                            roundInfo={roundInfo}
                            currentRound={currentRound}
                            bracketsArray={bracketsData.bracket}
                          />
                        }
                        <Bracket
                          rounds={roundInfo.rounds}
                          renderSeedComponent={CustomSeed}
                          mobileBreakpoint={1023}
                          bracketClassName='w-full lg:max-w-min'
                          swipeableProps={{ index: mobileBracketIndex, onChangeIndex: setMobileBracketIndex }}
                          roundClassName='round-text'
                        />
                      </>
                    }
                  </div>
                :
                <Bracket
                  rounds={bracketsData.bracket[0].rounds}
                  renderSeedComponent={CustomSeed}
                  mobileBreakpoint={1023}
                  bracketClassName='w-full lg:max-w-min'
                  roundClassName='round-text'
                />
              : <Spinner disableBackground className='w-1/4' />
            }
          </div>
        </BackgroundImage>
        {
          bracketsData && bracketsData.finished &&
          <BackgroundImage
            Tag='div'
            fluid={getBucketImage(imagesData, 'red-blue-neon.png', 'fluidData')}
            className='winner-bg'
          >
            <div className='flex flex-col justify-center items-center safe-space-container'>
              <div className='flex flex-col justify-center items-center z-10 pt-[20%] pb-[10%]'>
                <h2 className='winner-text winner-text-title'>{t('tournaments.brackets.winner')}</h2>
                <h2 className='winner-text winner-text-name'>{getWinner(bracketsData.bracket)}</h2>
              </div>
              <StaticImage
                src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/greyed-out-mokens-shield.png'
                alt={t('tournaments.brackets.shieldAlt')}
                placeholder='none'
                className='background-shield'
              />
              <StaticImage
                src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/mokens-champions.png'
                alt={t('tournaments.brackets.lineupAlt')}
                placeholder='none'
              />
            </div>
          </BackgroundImage>
        }
		</section>
  )
}

export default Brackets;
