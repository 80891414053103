import React, { FC } from 'react';

import { TOURNAMENTS_HERO_ID } from 'common/const';
import { ISectionQueryData } from 'common/types';

import TournamentsMobileHero from '@sections/Tournaments/TournamentsHero/Mobile';
import TournamentsDesktopHero from '@sections/Tournaments/TournamentsHero/Desktop';

const TournamentsHero:FC<ISectionQueryData> = ({ imagesData }) => {
  return (
    <section id={TOURNAMENTS_HERO_ID}>
      <TournamentsMobileHero imagesData={imagesData} />
      <TournamentsDesktopHero imagesData={imagesData} />
    </section>
  )
}

export default TournamentsHero;
