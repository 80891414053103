import React from 'react';
import { graphql } from 'gatsby';

import { FooterTheme } from 'common/context';
import { FooterStyle } from 'common/types';
import {
	CHAMPIONS_SERIES_ID,
	ESPORTS_ECOSYSTEM_ID,
	ESPORTS_STREAMING_ID,
	MLCS_ID,
	SPONSORSHIPS_OPPORTUNITIES_ID,
	SPONSORSHIPS_TIERS_ID,
	TOURNAMENTS_DETAILS_ID,
	TOURNAMENTS_HERO_ID,
	TOURNAMENTS_URL
} from 'common/const';

import Header from '@layout/Header';
import Footer from '@layout/Footer';
import TournamentsHero from '@sections/Tournaments/TournamentsHero';
import EsportsEcosystem from '@sections/Tournaments/EsportsEcosystem';
import ChampionshipSeries from '@sections/Tournaments/ChampionshipSeries';
import TournamentsDetails from '@sections/Tournaments/TournamentsDetails';
import EsportsStreaming from '@sections/Tournaments/EsportsStreaming';
import SponsorshipOpps from '@sections/Tournaments/SponsorshipOpps';
import MLCS from '@sections/Tournaments/MLCS';
import Brackets from '@sections/Tournaments/Brackets';
import Leaderboard from '@global/Leaderboard';

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
    allS3Object {
			nodes {
				localFile {
					relativePath
					childImageSharp {
						gatsbyImageData(placeholder: NONE)
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;

const Tournaments = ({ data }) => {
	const links = [
		{ title: 'footer.tournaments.tournamentsHero', url: `${TOURNAMENTS_URL}#${TOURNAMENTS_HERO_ID}` },
		{ title: 'footer.tournaments.esportsEcosystem', url: `${TOURNAMENTS_URL}#${ESPORTS_ECOSYSTEM_ID}` },
		{ title: 'footer.tournaments.championsSeries', url: `${TOURNAMENTS_URL}#${CHAMPIONS_SERIES_ID}` },
		{ title: 'footer.tournaments.tournamentsDetails', url: `${TOURNAMENTS_URL}#${TOURNAMENTS_DETAILS_ID}` },
		{ title: 'footer.tournaments.esportsStreaming', url: `${TOURNAMENTS_URL}#${ESPORTS_STREAMING_ID}` },
		{ title: 'footer.tournaments.sponsorshipsOpportunities', url: `${TOURNAMENTS_URL}#${SPONSORSHIPS_OPPORTUNITIES_ID}` },
		{ title: 'footer.tournaments.sponsorshipsTiers', url: `${TOURNAMENTS_URL}#${SPONSORSHIPS_TIERS_ID}` },
		{ title: 'footer.tournaments.mlcs', url: `${TOURNAMENTS_URL}#${MLCS_ID}` }
	];

	return (
		<>
			<Header />
			<TournamentsHero imagesData={data} />
			<EsportsEcosystem imagesData={data} />
			<Leaderboard imagesData={data} />
			<Brackets imagesData={data} />
			<ChampionshipSeries imagesData={data} />
			<TournamentsDetails imagesData={data} />
			<EsportsStreaming imagesData={data} />
			<SponsorshipOpps imagesData={data} />
			<MLCS />
			<FooterTheme.Provider value={FooterStyle.DARK}>
				<Footer links={links} />
			</FooterTheme.Provider>
		</>
	)
}

export default Tournaments;
