import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { MLCS_ID } from 'common/const';

import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss';

const MLCSDesktop = () => {
  const { t } = useTranslation();

  return (
    <section
      id={MLCS_ID}
      className='mlcs-background'
    >
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/backgrounds/MLCS-background-desktop.png'
        alt={t('tournaments.mlcs.background')}
        placeholder='none'
        className='absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-[1300px]'
      />
      <div
        className='
          flex
          flex-col
          justify-center
          items-center
          w-[90%]
          max-w-[1300px]
          space-y-[5%]
        '
      >
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/basic-mokens-logo.png'
          alt={t('tournaments.mlcs.basicLogo')}
          placeholder='none'
          quality={100}
          className='w-1/6'
        />
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/3-mokens-football-desktop.png'
          alt={t('tournaments.mlcs.decoration')}
          placeholder='none'
          className='w-4/5 max-w-[700px] aspect-[2.02]'
        />
        <p className='mlcs-text-desktop'>
          {`${t('tournaments.mlcs.description.part1')} ${t('tournaments.mlcs.description.part2')}`}
        </p>
      </div>
    </section>
  )
}

export default MLCSDesktop;
