import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { MLCS_ID } from 'common/const';

import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss';

const MLCSMobile = () => {
  const { t } = useTranslation();

  return (
    <section
      id={MLCS_ID}
      className='mlcs-mobile-bg'
    >
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/backgrounds/MLCS-background-mobile.png'
        alt={t('tournaments.mlcs.background')}
        placeholder='none'
        className='
          absolute
          aspect-[3.05]
          transform
          left-1/2
          bottom-0
          -translate-x-1/2
          -translate-y-[50%]
          w-[180%]
          -rotate-[10deg]
        '
      />
      <div className='w-[90%] max-w-[640px]'>
        <div className='w-full flex flex-col items-center space-y-4'>
          <StaticImage
            src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/ml-champions-logo.png'
            alt={t('tournaments.mlcs.mlChampsLogo')}
            placeholder='none'
            quality={100}
            className='aspect-[1.13] w-1/5'
          />
          <p className='mlcs-text-mobile'>
            {`${t('tournaments.mlcs.description.part1')}\n${t('tournaments.mlcs.description.part2')}`}
          </p>
          <h1 className='mlcs-text-hollow'>
            {t('tournaments.mlcs.title')}
          </h1>
        </div>
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/3-mokens-football-mobile.png'
          alt={t('tournaments.mlcs.decoration')}
          placeholder='none'
          className='aspect-[1.1] w-full mt-[5%]'
        />
      </div>
    </section>
  )
}

export default MLCSMobile;
