import React from 'react';

import { SPONSORSHIPS_TIERS_ID } from 'common/const';

import LangDepImg from '@global/LangDepImg';

const SponsorshipTiersDesktop = ({ cards }) => {
  return (
    <>
      <div
        id={SPONSORSHIPS_TIERS_ID}
        className='flex justify-center items-center w-[90%] max-w-[1300px]'
      >
        <div
          className='
            w-full
            flex
            justify-center
            space-x-4
          '
        >
          {
            cards.map(card => (
              <LangDepImg
                key={card.imgAlt}
                images={card.desktopImage}
                imgAlt={card.imgAlt}
                objectFit='contain'
                className='aspect-[0.477] h-full'
              />
            ))
          }
        </div>
      </div>
    </>
  )
}

export default SponsorshipTiersDesktop;
