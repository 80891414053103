import React, { FC, useEffect, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import useMediaChange from 'common/useMediaChange';

import { getBucketImage, renderFullDate } from 'common/utils';
import { ILeaderboardProps, ILeaderboardData } from 'common/types';
import { requestTournamentData, requestLeaderboardData } from 'common/tournamentsRequester';

import { StaticImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import LeaderboardEntry from './leaderboardEntry';
import Spinner from '@global/Spinner';

import './styles.scss'

const Leaderboard: FC<ILeaderboardProps> = ({ imagesData }) => {
	const { t, language } = useI18next();
	const { isDesktop } = useMediaChange();

	const tournamentId = process.env.GATSBY_CURRENT_TOURNAMENT_ID || '';

	const [tournamentData, setTournamentData] = useState<ILeaderboardData>({
		name: '',
		description: '',
		startDate: '',
		endDate: '',
		leaderboard: []
	});

	useEffect(() => {
		const getTournamentData = async () => {
			const tournamentResponse = await requestTournamentData();
			const selectedTournament = tournamentResponse.find(tournamentEntry => tournamentEntry._id === tournamentId);
			const leaderboardResponse = tournamentId
				? await requestLeaderboardData(tournamentId, 80)
				: [];

			selectedTournament && setTournamentData({
				name: selectedTournament.name,
				description: selectedTournament.description,
				startDate: selectedTournament.startsAt,
				endDate: selectedTournament.endsAt,
				leaderboard: leaderboardResponse,
			})

		}

		getTournamentData();
	}, [])

	const start = new Date(tournamentData.startDate);
	const startDateString = renderFullDate(start, language);

	const end = new Date(tournamentData.endDate);
	const endDateString = renderFullDate(end, language);

	const liveStatus = () => {
		const currentTime = new Date();

		if(currentTime.getTime() >= start.getTime() && currentTime.getTime() < end.getTime()) {
			return {
				styles: 'text-white bg-red live-tournament',
				state: 'tournaments.leaderboard.live'
			}
		}
		else {
			return {
				styles: 'text-black bg-gray-light max-w-max',
				state: currentTime.getTime() < start.getTime() ? 'tournaments.leaderboard.soon' : 'tournaments.leaderboard.finished'
			}
		}
	}

	return (
		<div className='flex flex-col items-center'>
			<BackgroundImage
				Tag='div'
				style={{
					backgroundPosition: 'top center'
				}}
				fluid={getBucketImage(imagesData, 'neon-light-desktop.png', 'fluidData')}
				className='flex flex-col items-center w-full'
			>
				<div
					className='
						flex
						flex-col
						w-[90%]
						pt-[5%]
						max-w-[640px]
						mb-4
						space-y-2
						items-center
						lg:max-w-[1024px]
					'
				>
					<StaticImage
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/community-tournaments-logo.png'
						alt={t('tournaments.leaderboard.imgAlt')}
						className='w-[90%] max-w-[400px] mb-[5%]'
					/>
					{
						tournamentData.name ?
						<div className='flex flex-col justify-center items-center space-y-4'>
							<h1
								className='
									text-green-lime
									uppercase
									italic
									text-center
									font-black
									font-sans-round
									leading-[80%]
									text-[1.5rem]
									lg:text-[5rem]
								'
							>
								{tournamentData.name}
							</h1>
							<p
								className='
									text-yellow-yolk
									uppercase
									italic
									font-bold
									text-center
									font-sans-round
									leading-none
									lg:text-[1.5rem]
								'
							>
								{tournamentData.description}
							</p>
							{
								isDesktop &&
								<div className='flex justify-center w-full space-x-[5%]'>
									<h2 className='dates'>{t('tournaments.leaderboard.startDate')} {startDateString}</h2>
									<h2 className='dates'>{t('tournaments.leaderboard.endDate')} {endDateString}</h2>
								</div>
							}
							<div
								className={`
									max-w-max
									p-2
									rounded-xl
									text-center
									font-sans-round
									font-black
									uppercase
									italic
									${liveStatus().styles}
								`}
							>
								{t(liveStatus().state)}
							</div>
						</div>
						: <Spinner disableBackground className='w-1/2 lg:w-1/4' />
					}
				</div>
			</BackgroundImage>

			<div className='flex flex-col items-center relative w-full'>
				<div className='bg-black -z-1 absolute w-full h-full bottom-0 ' />
				<StaticImage
					src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/moken-faceoff.png'
					alt={t('tournaments.hero.faceoff')}
					placeholder='none'
					loading='eager'
					transformOptions={{ grayscale: true }}
					className='
						w-full
						-z-1
						absolute
						bottom-0
						aspect-[1916/794]
					'
				/>
				<div
					className='
						w-full
						h-full
						absolute
						-z-1
						bottom-0
						bg-gradient-to-b
						from-transparent
						to-purple-dark
						mix-blend-screen
					'
				/>
				<div
					className='
						w-[90%]
						max-w-[640px]
						p-3
						my-[3%]
						border-[3px]
						rounded-lg
						border-yellow-yolk
						bg-black
						lg:w-1/2
						lg:max-w-[1024px]
					'
				>
					<h1
						className='
							w-full
							text-[2rem]
							font-bold
							uppercase
							italic
							text-center
							text-yellow-yolk
							font-sans-round
							lg:text-[2.5rem]
						'
					>
						{t('tournaments.leaderboard.title')}
					</h1>
					{!isDesktop && tournamentData.startDate &&
						<div className='flex justify-between w-full'>
							<h2 className='dates'> {t('tournaments.leaderboard.startDate')} {startDateString}</h2>
							<h2 className='dates'> {t('tournaments.leaderboard.endDate')} {endDateString}</h2>
						</div>
					}
					<div className='flex justify-evenly pl-[3%] pr-[2rem]'>
						<h2 className='column-title generic-cell'> {t('tournaments.leaderboard.rank')} </h2>
						<h2 className='column-title generic-cell' />
						<h2 className='column-title username-cell'> {t('tournaments.leaderboard.username')} </h2>
						<h2 className='column-title generic-cell'> {t('tournaments.leaderboard.goals')} </h2>
						<h2 className='column-title generic-cell'> {t('tournaments.leaderboard.points')} </h2>
					</div>
					<div className='rank-board px-[1%] space-y-3 h-[400px] lg:h-[600px] overflow-y-scroll'>
						{tournamentData.leaderboard && tournamentData.leaderboard.map(entry => {
							return <LeaderboardEntry
								key={entry.rank}
								imagesData={imagesData}
								username={entry.username}
								rank={entry.rank}
								name={entry.name}
								avatarUrl={entry.avatarUrl}
								scores={entry.scores}
							/>
						})}
					</div>
				</div>
			</div>

		</div >
	)
}

export default Leaderboard