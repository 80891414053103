import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { GatsbyImage } from 'gatsby-plugin-image';

import './styles.scss';

const InfoBox = ({ logo, title, description, className='' }) => {
  const { t } = useTranslation();

  return (
    <div
      className={className}
    >
      <div className='
        flex
        flex-col
        items-center
        justify-center
        space-x-4
        py-8
        min-h-[360px]
        lg:flex-row
        lg:min-h-0
        lg:py-4
      '
    >
        <div className='w-1/5 min-w-[150px] flex justify-center items-center'>
          <GatsbyImage
            image={logo}
            alt={t('tournaments.logoAlt')}
            className='w-full'
          />
        </div>
        <div className='
            w-full
            flex
            flex-col
            justify-center
            space-y-4
            text-left
          '
        >
          <h3 className='info-box-text info-box-text-title'>{title}</h3>
          <p className='info-box-text info-box-text-desc'>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default InfoBox;
