import React from 'react';

import { IRenderSeedProps, Seed, SeedItem, SeedTeam } from 'react-brackets';

import './styles.scss';

const CustomSeed = ({ seed }: IRenderSeedProps) => {
  const checkWinner = (scoreA: number, scoreB: number) => {
    return scoreA > scoreB ? 'match-winner' : 'match-loser';
  }

  return (
    <Seed mobileBreakpoint={1023} className='seed-wrapper'>
      <SeedItem className='seed-item' >
        <div>
          <SeedTeam className={`seed-team seed-team-top ${checkWinner(seed.teams[0]?.score, seed.teams[1]?.score)}`}>
						<p>{seed.teams[0]?.name || '-'}</p>
						<p>{seed.teams[0]?.score || '-'}</p>
					</SeedTeam>
          <SeedTeam className={`seed-team seed-team-bottom ${checkWinner(seed.teams[1]?.score, seed.teams[0]?.score)}`}>
            <p>{seed.teams[1]?.name || '-'}</p>
            <p>{seed.teams[1]?.score || '-'}</p>
          </SeedTeam>
        </div>
      </SeedItem>
    </Seed>
  )
}

export default CustomSeed;
