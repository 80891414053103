import React, { useState } from 'react';
import useMediaChange from 'common/useMediaChange';

import { SPONSORSHIPS_TIERS_ID } from 'common/const';

import FadeSlider from '@global/FadeSlider';
import FadeSlide from '@global/FadeSlider/FadeSlide';
import LangDepImg from '@global/LangDepImg';

const SponsorshipTiersMobile = ({cards}) => {
  const { isMobile } = useMediaChange();

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <div
      id={SPONSORSHIPS_TIERS_ID}
      className='w-[90%] max-w-[640px] flex justify-center'
    >
      <FadeSlider
        pages={cards}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        paginationStyles='py-[5%] lg:py-[3%]'
        paginationSize={isMobile ? 'sm' : 'md'}
        fadeInterval={10000}
        className='w-full'
      >
        {
          cards.map((card, ownIndex) => (
            <FadeSlide
              key={card.imgAlt}
              initial={{ opacity: 0 }}
              animate={{
                opacity: ownIndex === currentIndex ? 1 : 0,
                display: ownIndex === currentIndex ? 'flex' : 'none',
              }}
              transition={{ duration: 1 }}
              className='justify-center'
            >
              <LangDepImg
                images={cards[currentIndex].mobileImage}
                imgAlt={cards[currentIndex].imgAlt}
                objectFit='contain'
                className='w-4/5 max-w-[350px]'
              />
            </FadeSlide>
          ))
        }
      </FadeSlider>
    </div>
  )
}

export default SponsorshipTiersMobile;
