import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useMediaChange from 'common/useMediaChange';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';
import { CHAMPIONS_SERIES_ID } from 'common/const';

import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss';

const ChampionshipSeries: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();
	const { isDesktop } = useMediaChange();

	const champBackground = [
		getBucketImage(imagesData, 'joystick-background.png', 'fluidData'),
		'linear-gradient(#1B111D, #782699)'
	];

	return (
		<BackgroundImage
			id={CHAMPIONS_SERIES_ID}
			Tag='section'
			fluid={champBackground}
			className='champ-background'
		>
			<div className='flex flex-col w-[90%] lg:flex-row max-w-[640px] lg:max-w-[1300px] lg:gap-[5%]'>
				<div className='champ-text space-y-[10%] lg:flex lg:flex-col lg:justify-center lg:w-1/2'>
					<h1 className='champ-text champ-text-lg mt-[5%]'>
						{
							isDesktop
								? `${t('tournaments.champSeries.title.part1')}\n${t('tournaments.champSeries.title.part2')}`
								: `${t('tournaments.champSeries.title.part1')} ${t('tournaments.champSeries.title.part2')}`
						}
					</h1>
					<StaticImage
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/tournaments-scheme.png'
						alt={t('tournaments.champSeries.imgAlt')}
						placeholder='none'
						objectFit='contain'
						className='aspect-[874/590] mobile-image'
					/>
					<p className='champ-text-sm'>
						{t('tournaments.champSeries.description1')}
					</p>
					<p className='champ-text-sm'>
						{t('tournaments.champSeries.description2')}
					</p>
				</div>
				<StaticImage
					src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/tournaments-scheme.png'
					alt={t('tournaments.champSeries.imgAlt')}
					placeholder='none'
					objectFit='contain'
					className='aspect-[874/590] desktop-image'
				/>
			</div>
		</BackgroundImage>
	)
}

export default ChampionshipSeries;
