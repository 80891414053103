import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';

import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss'

const TournamentsMobileHero:FC<ISectionQueryData> = ({ imagesData }) => {
  const { t } = useTranslation();

  return (
    <BackgroundImage
      Tag='div'
      fluid={getBucketImage(imagesData, 'tournaments-hero-mobile-background.png', 'fluidData')}
      critical
      className='overflow-hidden lg:hidden'
    >
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/blue-girl.png'
        alt={t('tournaments.hero.blueGirl')}
        placeholder='none'
        loading='eager'
        className='team-leads blue-girl'
      />
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/red-boy.png'
        alt={t('tournaments.hero.redBoy')}
        placeholder='none'
        loading='eager'
        className='team-leads red-boy'
      />
      <div className='w-full flex justify-center items-center'>
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/ml-champions-logo.png'
          alt={t('tournaments.hero.logo.mobile')}
        placeholder='none'
        loading='eager'
          className='w-1/2 mb-[400px] mt-[100px]'
        />
      </div>
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/blue-team.png'
        alt={t('tournaments.hero.blueTeam')}
        placeholder='none'
        loading='eager'
        className='moken-teams moken-teams-left'
      />
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/red-team.png'
        alt={t('tournaments.hero.redTeam')}
        placeholder='none'
        loading='eager'
        className='moken-teams moken-teams-right'
      />
    </BackgroundImage>
  )
}

export default TournamentsMobileHero;
