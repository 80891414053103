import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITournamentSlidesProps } from 'common/types';

import { GatsbyImage } from 'gatsby-plugin-image';

import '@sections/Tournaments/TournamentsDetails/styles.scss';

const TournamentSlides: FC<ITournamentSlidesProps> = ({
	currIndex,
	themes
}) => {
	const { t } = useTranslation();

	return (
		<>
			<div
				className='
          w-full
          flex
          flex-col
          justify-around
          items-center
          space-y-4
          px-4
          lg:px-8
          lg:flex-row
          lg:space-x-4
          lg:space-y-0
        '
			>
				<GatsbyImage
					image={themes[currIndex].logoImage}
					alt={t('tournaments.tournamentsDetails.logoAlt')}
					objectFit='contain'
					className='
            w-full
            max-w-[300px]
            lg:max-w-[500px]
            lg:w-1/2
          '
				/>
				<div className='w-full min-h-[300px] flex flex-col justify-center lg:w-1/2'>
					<h2
						className='
              w-full
              tournament-text-description
              uppercase
              text-center
							mb-[10%]
              lg:text-right
            '
					>
						{themes[currIndex].textData.mainBody.part1}{' '}
						<span className={`${themes[currIndex].color}`}>
							{themes[currIndex].textData.mainBody.highlight}{' '}
						</span>
						{themes[currIndex].textData.mainBody.part2}
					</h2>
					<div
						className='
							w-full
							flex
							flex-col-reverse
							items-center
							lg:items-end
							lg:flex-col
							lg:justify-end
						'
					>
						<h3
							className='
								flex
								w-full
								tournament-text
								flex-col
								text-center
								capitalize
								px-4
								lg:px-0
								lg:pt-0
								lg:mx-4
								lg:text-right
							'
						>
							{themes[currIndex].textData.additional.main}
							<span className={`${themes[currIndex].color}`} >
								{themes[currIndex].textData.additional.highlight}
							</span>
						</h3>
						<GatsbyImage
							image={themes[currIndex].bannerImage}
							alt={t('tournaments.tournamentsDetails.bannerAlt')}
							objectFit='contain'
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default TournamentSlides;
