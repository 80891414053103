import React, { FC } from 'react';
import useMediaChange from 'common/useMediaChange';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';
import { ESPORTS_ECOSYSTEM_ID } from 'common/const';

import BackgroundImage from 'gatsby-background-image';

import './styles.scss';

const EsportsEcosystem: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();

	const backgroundImage = [
		getBucketImage(imagesData, 'esports-ecosystem-desktop-background.png', 'fluidData'),
		{
			...getBucketImage(imagesData, 'esports-ecosystem-mobile-background.png', 'fluidData'),
			media: '(max-width: 768px)',
		},
	];

	return (
		<BackgroundImage
			id={ESPORTS_ECOSYSTEM_ID}
			Tag='section'
			style={{
				backgroundPosition: 'bottom center'
			}}
			fluid={backgroundImage}
			className='tournaments-esports-background'
		>
			<div className='tournaments-esports-container'>
				<h1 className='tournaments-esports-text tournaments-esports-text-lg'>{t('tournaments.esportsEco.titleFirstLine')}</h1>
				<h1 className='tournaments-esports-text tournaments-esports-text-lg'>{t('tournaments.esportsEco.titleSecondLine')}</h1>
				<p className='tournaments-esports-text tournaments-esports-text-sm'>{t('tournaments.esportsEco.description')}</p>
			</div>
		</BackgroundImage>
	)
}

export default EsportsEcosystem;
