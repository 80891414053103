import React, { FC } from 'react';

import { IGroupIndicator } from 'common/types';

const GroupIndicator: FC<IGroupIndicator> = ({
	roundInfo,
	currentRound,
	bracketsArray
}) => {
	const playerAmount = 16;

  return (
    <div className='flex flex-col justify-center items-center'>
			<h2 className='round-title'>
				{`${roundInfo?.title || ''}${ currentRound < bracketsArray.length-1 ? `/${bracketsArray.length-1}` : '' }`}
			</h2>
			{
				(currentRound + 1) <= (bracketsArray.length-1) && bracketsArray.length > 1 &&
				<h2 className='round-subtitle'>
					{(currentRound + 1) * playerAmount}/{(bracketsArray.length-1) * playerAmount}
				</h2>
			}
		</div>
  )
}

export default GroupIndicator;
