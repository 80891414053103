import axios from 'axios';

const http = axios.create({
	baseURL: process.env.GATSBY_TOURNAMENTS_API_URL,
	headers: {
		"x-api-key": process.env.GATSBY_TOURNAMENTS_API_KEY,
	},
});

export const requestTournamentData = async () => {
	try {
		const resp = await http.get(
			`/tournaments/?filter=ALL`,
		);
		return resp.data.data;
	} catch (err) {
		console.log(err);
		return;
	}
}

export const requestLeaderboardData = async (tournamentId, limit = 10) => {
	try {
		const resp = await http.get(
			`/tournaments/leaderboard?tournamentId=${tournamentId}&limit=${limit}`,
		);
		return resp.data.data.entries;
	} catch (err) {
		console.log(err);
		return;
	}
}